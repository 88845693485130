<template>
    <div class="pageBox">
        <div class="topBox">
            <div class="titleBox titleCss">
                <div>
                    <img src="../../assets/siteImg/title.png">
                    站点环境波动对比
                </div>
                <div class="operBar">
                    <el-tree-select 
                    v-model="zoneid" 
                    node-key="envId" 
                    :data="stations" 
                    check-strictly 
                    :props="defaultProps"/>
                    <div class="marginCss"></div>
                    <el-date-picker
                    v-model="TimeRang"
                    type="daterange"
                    unlink-panels
                    range-separator="-"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    />
                    <el-select v-model="sener" class="marginCss" multiple :multiple-limit="2" placeholder="请选择">
                        <el-option
                        v-for="item in seners"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                    <el-button class="marginCss" type="primary" @click="GetData(1)">查询</el-button>
                </div>
            </div>
            <div class="titleMsgBox titleCss">
                <div class="chartBox">
                    <floatBar 
                    :title="`${titleA}波动对比`" 
                    :colors="['#F6BF5E','#3ABB83']"
                    :yAxis="yAxisA"
                    :minList="dataListA.minList" 
                    :maxList="dataListA.maxList"/>
                </div>
                <div class="chartBox">
                    <floatBar 
                    :title="`${titleB}波动对比`" 
                    :colors="['#3ABB83','#F6F853']"
                    :yAxis="yAxisB"
                    :minList="dataListB.minList" 
                    :maxList="dataListB.maxList"/>
                </div>
               
            </div>
        </div>
        <div class="topBox centerBox">
            <div class="titleBox titleCss">
                <div>
                    <img src="../../assets/siteImg/title.png">
                    环境指标区域稳定性分析
                </div>
                <div class="operBar">
                    <el-tree-select 
                    v-model="zoneid2" 
                    node-key="envId" 
                    :data="stations" 
                    check-strictly 
                    :props="defaultProps"/>
                    <div class="marginCss"></div>
                    <el-date-picker
                    v-model="TimeRang2"
                    type="daterange"
                    unlink-panels
                    range-separator="-"
                    value-format="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    />
                    <el-select v-model="sener2" class="marginCss" multiple :multiple-limit="2" placeholder="请选择">
                        <el-option
                        v-for="item in seners"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
                    <el-button class="marginCss" type="primary" @click="GetData(2)">查询</el-button>
                </div>
            </div>
            <div class="titleMsgBox titleCss">
                <div class="chartBox">
                    <barVerc 
                    :title="`区域${title2A}稳定性`"
                    :name='`${title2A}稳定性`'  
                    :subtext="`${title2A}值偏差情况（标准差计算）`"
                    :colors="['#F6BF5E','#3ABB83']"
                    :yAxis="yAxis2A"
                    :dataList="dataList2A"/>
                </div>
                <div class="chartBox">
                    <barVerc 
                    :title="`区域${title2B}稳定性`"
                    :name='`${title2B}稳定性`'  
                    :subtext="`${title2B}值偏差情况（标准差计算）`"
                    :colors="['#3ABB83','#F6F853']"
                    :yAxis="yAxis2B"
                    :dataList="dataList2B" 
                    />
                </div>
               
            </div>
        </div>
        <div class="topBox centerBox">
            <div class="titleBox">
                <img src="../../assets/siteImg/title.png">
                环境对比统计分析
            </div>
            <div class="titleMsgBox">
                <div class="operBar">
                    <div class="timeBox marginCss">
                        <el-date-picker
                        v-model="historyTimeRang"
                        type="datetimerange"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        range-separator="-"
                        unlink-panels
                        format="YYYY-MM-DD HH:mm:ss"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        />
                    </div>
                    <div>
                        <div style="margin-bottom: 10px;">
                            <el-tree-select
                                class="marginCss"
                                v-model="positions"
                                node-key="envId"
                                :check-strictly="true"
                                :data="TreeSelectData"
                                :props="treeProps"
                                @change="treeChange"
                                :render-after-expand="false"
                            />
                            <el-select v-model="devId" class="marginCss" @change="devChange"  placeholder="请选择监测设备">
                                <el-option
                                v-for="item in devList"
                                :key="item.deviceId"
                                :label="item.deviceName"
                                :value="item.deviceId"
                                />
                            </el-select>
                            <el-select v-model="senerId" class="marginCss"  placeholder="请选择监测指标">
                                <el-option
                                v-for="item in senerList"
                                :key="item.envirParamType"
                                :label="item.envirParamName"
                                :value="item.envirParamType"
                                />
                            </el-select>
                        </div>
                        <div>
                            <el-tree-select
                                class="marginCss"
                                v-model="positions2"
                                node-key="envId"
                                :check-strictly="true"
                                :data="TreeSelectData"
                                :props="treeProps"
                                @change="treeChange2"
                                :render-after-expand="false"
                            />
                            <el-select v-model="devId2" @change="devChange2" class="marginCss"  placeholder="请选择监测设备">
                                <el-option
                                v-for="item in devList2"
                                :key="item.deviceId"
                                :label="item.deviceName"
                                :value="item.deviceId"
                                />
                            </el-select>
                            <el-select v-model="senerId2" class="marginCss"  placeholder="请选择监测指标">
                                <el-option
                                v-for="item in senerList2"
                                :key="item.envirParamType"
                                :label="item.envirParamName"
                                :value="item.envirParamType"
                                />
                            </el-select>
                        </div>
                    </div>
                    <el-button class="marginCss" type="primary" @click="CXhistory">查询</el-button>
                </div>
               <div class="compareChart">
                    <lineChart :series="historyLine" :yAxis="historyAxis" />
               </div>
            </div>
        </div>
    </div>
</template>
<script>

import floatBar from '@/components/charts/floatBar.vue'
import barVerc from '@/components/charts/barVerc.vue'
import lineChart from '@/components/charts/lineChart.vue'
import { site_metaData, site_listDeviceData, site_history} from '@/api/site-dataAnalysis.js'
import { site_envList } from '@/api/site-home.js'
import { siteOper_devList } from '@/api/site-operationPage.js'
import { mapGetters } from 'vuex'

import moment from 'moment'
export default{
    components:{
        floatBar,
        barVerc,
        lineChart,
    },
    computed:{
        ...mapGetters(['siteId'])
    },
    data(){
        return{
            stations:[],
            defaultProps:{
                children: 'children',
                label: 'envName',
            },
            zoneid:null,
            sener:['01','02'],
            TimeRang:[moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            zoneid2:null,
            sener2:['01','02'],
            TimeRang2:[moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            seners:[],
            titleA:'暂无数据',
            titleB:'暂无数据',
            yAxisA: [],
            yAxisB: [],
            yAxis2A: [],
            yAxis2B: [],
            title2A:'暂无数据',
            title2B:'暂无数据',
            dataList2A:[],
            dataList2B:[],
            dataListA:{
                minList:[],
                maxList:[],
            },
            dataListB:{
                minList:[],
                maxList:[],
            },
            historyLine:[
                {name: '温度（℃）',type: 'line',smooth: true, data:[]},
                {name: '湿度（%RH）', yAxisIndex: 1, type: 'line', smooth: true,data:[]}
            ],
            historyAxis:[
                {name: '温度(℃)', type: 'value'},
                {name: '湿度(%RH)',alignTicks: true, type: 'value'}
            ],

            TreeSelectData:[],
            treeProps:{
                label:'envName',
                children:'children'
            },
            historyTimeRang:[moment().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'), moment().format('YYYY-MM-DD HH:mm:ss')],

            positions:null,
            devList:[],
            devId:'',
            senerId:'',
            senerList:[],
            
            positions2:null,
            devList2:[],
            devId2:'',
            senerId2:'',
            senerList2:[],

            TableList:[],

            flag1:true,
            flag2:true,
        }
    },
    mounted(){
        this.readyData()
    },
    methods:{
        moment,
        readyData(){
            site_metaData().then(r=>{
                let arr=[]
                let obj = r.data
                for(let i in obj) {
                    let objs = {
                        label:obj[i].envirParamName,
                        value:obj[i].envirParamType,
                        unit:obj[i].envirDataUnit
                    }
                    arr.push(objs) 
                }
                this.seners = arr
                // this.sener = [this.seners[0].value]
                // this.sener2 = [this.seners[0].value]
            })

            let query = {
                'siteId':this.siteId,
                'isSelf':false,
                'isTree':true,
            }
            site_envList(query).then(r=>{
                this.TreeSelectData = r.data
                this.stations = r.data
                this.zoneid = r.data[0].envId
                this.zoneid2 = r.data[0].envId
                this.GetData(1)
                this.GetData(2)
                this.treeChange(r.data[0].envId)
                this.treeChange2(r.data[0].envId)   
            })
        },
        
        GetData(type){
            if(type==1){
                if(!this.sener||this.sener.length<2||!this.TimeRang){
                    this.$message.warning('请补全筛选信息且至少选择两个检测指标')
                    return
                } 
            }else{
                if(!this.sener2||this.sener2.length<2||!this.TimeRang2){
                    this.$message.warning('请补全筛选信息且至少选择两个检测指标')
                    return
                } 
            }
            let query = {
                'envid':type==1?this.zoneid:this.zoneid2,
                'start':type==1?this.TimeRang[0]:this.TimeRang2[0],
                'end':type==1?this.TimeRang[1]:this.TimeRang2[1],
                'deviceType':type==1?this.sener.join(','):this.sener2.join(','),
                'siteid':this.siteId
            }
            site_listDeviceData(query).then(r=>{
                if(type == 1){
                    let data0 = r.data[this.sener[0]]
                    let data1 = this.sener.length==2?r.data[this.sener[1]]:[]
                   this.yAxisA = []
                   this.yAxisB = []
                   let seners = this.sener.map(i=>{
                        let obj = this.seners.find(el=>{
                            return el.value == i
                        })
                        return obj
                    })
                    this.titleA = `${seners[0].label}(${seners[0].unit})`
                    this.titleB = `${seners[1].label}(${seners[1].unit})`
                    
                    this.dataListA.minList = data0.map(i=>{
                        this.yAxisA.push(i.envname)
                        return i.min
                    })
                    this.dataListA.maxList = data0.map(i=>{
                        return i.max
                    })
                    this.dataListB.minList = data1.map(i=>{
                        this.yAxisB.push(i.envname)
                        return i.min
                    })
                    this.dataListB.maxList = data1.map(i=>{
                        return i.max
                    })
                }else{
                    let data0 = r.data[this.sener2[0]]
                    let data1 = this.sener2.length==2?r.data[this.sener2[1]]:[]
                    let seners = this.sener2.map(i=>{
                        let obj = this.seners.find(el=>{
                            return el.value == i
                        })
                        return obj
                    })
                    this.title2A = `${seners[0].label}(${seners[0].unit})`
                    this.title2B = `${seners[1].label}(${seners[1].unit})`
                    this.yAxis2A = []
                    this.yAxis2B = []
                    
                    this.dataList2A= data0.map(i=>{
                        this.yAxis2A.push(i.envname)
                        return i.stability
                    })
                    this.dataList2B= data1.map(i=>{
                        this.yAxis2B.push(i.envname)
                        return i.stability
                    })
    
                }

            })
        },
        treeChange(val){
            this.positions = val
            let query = {
                siteId:this.siteId,
                envid:val
            }
            this.devId = null
            this.devList = []
            this.senerId = null
            this.senerList = []
            siteOper_devList(query).then(r=>{
                this.devList = r.data
                this.devChange(r.data[0].deviceId)
                
            })
        },
        treeChange2(val){
            this.positions2 = val
            let query = {
                siteId:this.siteId,
                envid:val
            }
            this.devId2 = null
            this.devList2 = []
            this.senerId2 = null
            this.senerList2 = []
            siteOper_devList(query).then(r=>{
                this.devList2 = r.data
                this.devChange2(r.data[0].deviceId)
            })  
        },
        devChange(id){
            this.devId = id
            this.senerId = null
            let senerList = this.devList.find(i=>{
              return  i.deviceId == this.devId
            }).deviceParamsVal
            
            this.senerList = []
            for(let key in senerList){
                this.senerList.push(senerList[key])
            }
            if(this.flag1&&this.senerList.length>0){
                this.senerId = this.senerList[0].envirParamType
                this.flag1 = false
            }
        },
        devChange2(id){
            this.devId2 = id
            this.senerId2 = null
            let senerList2 = this.devList2.find(i=>{
              return  i.deviceId == this.devId2
            }).deviceParamsVal
            this.senerList2 = []
            for(let key in senerList2){
                this.senerList2.push(senerList2[key])
            }
            if(this.flag2&&this.senerList.length>0){
                this.senerId2 = this.senerList2[0].envirParamType
                this.flag2 = false
                this.CXhistory()
            }
            
        },
        CXhistory(){
            if(!this.senerId||!this.senerId2||!this.historyTimeRang){
                this.$message.warning('请补全查询条件')
                return
            }
            this.GetHistoryData()
        },
        GetHistoryData(){
            let query = {
                deviceId:this.devId,
                siteId:this.siteId,
                envirParamType:this.senerId,
                startDateTime:this.historyTimeRang[0],
                endDateTime:this.historyTimeRang[1]
            }
            let query2 = {
                deviceId:this.devId2,
                siteId:this.siteId,
                envirParamType:this.senerId2,
                startDateTime:this.historyTimeRang[0],
                endDateTime:this.historyTimeRang[1]
            }
            site_history(query).then(r=>{
                let data0 = {
                    name:r.data.deviceName,
                    data:r.data.data.map(el=>{
                        return [moment(el.collectTime).valueOf(), el.envirParamValue]
                    })
                }
                site_history(query2).then(res=>{
                    let data1= {
                        name:res.data.deviceName,
                        data:res.data.data.map(el=>{
                            return [moment(el.collectTime).valueOf(), el.envirParamValue]
                        })
                    }
                    let senerData0 = this.senerList.find(e=>{
                        return e.envirParamType == this.senerId
                    })
                    let senerData1 = this.senerList2.find(e=>{
                       return e.envirParamType == this.senerId2
                    })
                    let name0 =  `${data0.name}-${senerData0.envirParamName}(${senerData0.envirDataUnit})`
                    let name1 =  `${data1.name}-${senerData1.envirParamName}(${senerData1.envirDataUnit})`
                    this.historyLine[0].data = data0.data
                    this.historyLine[0].name = name0
                    this.historyAxis[0].name = name0
                    this.historyLine[1].data = data1.data
                    this.historyLine[1].name = name1
                    this.historyAxis[1].name = name1
                })
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.titleMsgBox{
    width: 100%;
    height: calc(100% - 60px);
}

.titleCss{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.titleBox{
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CFCFCF;
    box-sizing: border-box;
    padding:0px 20px;
    margin-bottom: 10px;
    img{
        width: 20px;
        height: 30px;
        object-fit: contain;
        vertical-align: middle;
    }
}
.marginCss{
    margin-left: 20px;
}
.pageBox{
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
.topBox{
    width: 100%;
    height: 500px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    .operBar{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .compareChart{
        width: 100%;
        height: calc(100% - 100px);
    }
    .timeBox{
        // width: 360px;
    }
    .chartBox{
        width: 50%;
        height: 100%;
    }
}
.centerBox{
    margin-top: 20px;
}
.paddingCss{
    padding: 20px;
    box-sizing: border-box;
}
</style>