import request from '@/utils/request'

export function siteOper_typeCount(data) { //设备类型数量统计
  return request({
    url: '/site/data/typeCount',
    method: 'get',
    params:data
  })
}

export function siteOper_onlineCount(data) { //设备类型在线数量统计
  return request({
    url: '/site/data/onlineCount',
    method: 'get',
    params:data
  })
}

export function siteOper_devList(data) { //获取设备列表
  return request({
    url: '/site/data/devList',
    method: 'get',
    params:data
  })
}

export function siteOper_devList1(data) { //获取设备列表1
  return request({
    url: '/site/data/devList1',
    method: 'get',
    params:data
  })
}

