<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      title:{
        type: String,
        default: '温度波动对比'
      },
      colors: {
        type: Array,
        default: ()=>{
          return ['#3ABB83','#F6F853','#F6BF5E', '#F65559']
        }
      },
      yAxis:{
        type:Array,
        default:()=>{
            return  ['第一展览厅', '第二展览厅', '第三展览厅', '第四展览厅', '第五展览厅', '第六展览厅']
        }
      },
      minList:{
        type:Array,
        default:()=>{
            return [20, 10, 15, 12, 3, 8]
        }
      },
      maxList:{
        type:Array,
        default:()=>{
            return [50, 60, 52, 54, 59, 57]
        }
      }
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'maxList':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
    },
    mounted() {
      this.refreshData()
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 500)
      window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            title:{
                text:this.title,
                left:'center'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    var tar0 = params[0];
                    var tar1 = params[1];
                    return tar0.name + '<br/>' + tar0.seriesName + ' : ' + tar0.value + '<br/>' +  tar1.seriesName + ' : ' + (Number(tar1.value) + Number(tar0.value));
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            color:this.colors,
            yAxis: {
                type: 'category',
                splitLine: { show: false },
                data:this.yAxis
            },
            xAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '最小值',
                    type: 'bar',
                    stack: 'Total',
                    // barWidth:30,
                    itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                    },
                    emphasis: {
                        itemStyle: {
                        borderColor: 'transparent',
                        color: 'transparent'
                        }
                    },
                    data: this.minList
                },
                {
                    name: '最大值',
                    type: 'bar',
                    // barWidth:30,
                    stack: 'Total',
                    data: this.maxList.map((e,index)=>{
                        return e - this.minList[index]
                    })
                }
            ]
          })
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>